import {
  GET_APPEL_EMETTRE,
  GET_APPEL_EMETTRE_BY_CONTACT,
  ADD_APPEL_EMETTRE,
  DELETE_APPEL_EMETTRE,
  DELETE_APPEL_EMETTRE_BY_CONTACT,
  GET_ETAPES,
  UPDATE_ETAPE,
  UPDATE_APPEL_EMETTRE,
  UPDATE_TIME_APPEL_EMETTRE,
  UPDATE_DATE_APPEL_EMETTRE,
  UPDATE_APPEL_EMETTRE_BY_CONTACT,
  GET_ACTIVITES,
  GET_CALLS,
  GET_CALL_ENDED,
  GET_CALLS_CHIP,
  GET_CONTACTS,
  GET_CONTACTS_NOT_AFFECTED,
  GET_CONTACTS_NOT_AFFECTED_ALARME,
  GET_CONTACTS_NOT_AFFECTED_MOBILE,
  GET_VENTES,
  GET_CONTACTS_SOUS_CONTRAT_BY_ID,
  ADD_NOTE,
  GET_NOTES,
  UPDATE_NOTE,
  DELETE_NOTE,
  GET_VENTE_BY_ID,
  GET_PIPELINE_CONTACT,
  GET_PIPELINE_CONTACT_BY_USER,
  GET_PIPELINE_CONTACT_MOBILE,
  GET_PIPELINE_CONTACT_MOBILE_BY_USER,
  MODIFY_STATUT_PIPELINE,
  MODIFY_STATUT_PIPELINE_MOBILE,
  GET_AFFECTED_CONTACTS,
  GET_AFFECTED_CONTACTS_ALARME,
  GET_AFFECTED_CONTACTS_MOBILE,
  GET_PIPELINE_CONTACT_AFFECTED,
  GET_PIPELINE_CONTACT_AFFECTED_BY_USER,
  GET_PIPELINE_CONTACT_AFFECTED_MOBILE,
  GET_PIPELINE_CONTACT_AFFECTED_MOBILE_BY_USER,
  GET_CALL_ID,
  UPDATE_APPEL_EMETTRE_NOTIFIED,
  GET_RH_TIMELINE,
  UPLOAD_CONTACTS,
  GET_RDV,
  GET_RDV_BY_CONTACT,
  UPDATE_RDV_BY_USER,
  UPDATE_RDV_BY_CONTACT,
  UPDATE_TIME_RDV,
  GET_RDV_AFFECTED_TO_USER,
  GET_RDV_CREATED_BY_USER,
  ADD_RDV,
  ACCEPT_AFFECTED_CONTACT,
  GET_CALLS_FOR_DISPLAY,
  GET_SALES_FOR_DISPLAY,
  GET_RESEARCH_JOURNAL,
  GET_DEMANDE_AFFECTAION,
  UPDATE_DEMANDE_AFFECTAION,
  GET_SOUS_CONTRAT,
  GET_FUTURE_ECHEANCE,
  GET_FUTURE_BESOIN,
  GET_HORS_CIBLE,
  GET_SOUS_CONTRAT_ALARME,
  GET_FUTURE_ECHEANCE_ALARME,
  GET_FUTURE_BESOIN_ALARME,
  GET_KO_BUDGET_ALARME,
  GET_KO_AUTORITE_ALARME,
  GET_KO_REFUS_ALARME,
  GET_PAS_INTERESSE_ALARME,
  GET_KO_AUTRE_ALARME,
  GET_KO_BUDGET_MOBILE,
  GET_KO_AUTORITE_MOBILE,
  GET_KO_REFUS_MOBILE,
  GET_PAS_INTERESSE_MOBILE,
  GET_KO_AUTRE_MOBILE,
  GET_SOUS_CONTRAT_MOBILE,
  GET_FUTURE_ECHEANCE_MOBILE,
  GET_FUTURE_BESOIN_MOBILE,
  GET_CONTACTS_HORSCIRCUIT_ALARME,
  UPDATE_CONTACTS_HORSCIRCUIT_ALARME

} from "../actions/types";

const initialState = {
  callEnded: {},
  suivis: [],
  ventes: { ventes: [] },
  contactsHorsCircuit:[],
  appelsEmettre: [],
  rdvByUser: [],
  rdvByContact: [],
  rdvCreatedByUser: [],
  appelsEmettreByContact: [],
  appelAEmettresOutDated: [],
  appelAEmettresToDay: [],
  appelAEmettresToMorrow: [],
  appelAEmettresTwoDays: [],
  appelAEmettresThreeDays: [],
  appelAEmettresFourDays: [],
  appelAEmettresFiveDays: [],
  appelAEmettresOneMonth: [],
  appelAEmettresSixMonth: [],

  appelAEmettresAboveFiveDays: [],
  appelAEmettresAssignedCategorised: [],
  appelAEmettresAssigned: [],
  rdvCree: [],
  failedUpload: [],
  RHTimeline: [],
  etapes: [],
  activites: [],
  calls: { call: [] },
  updatedCall: {},
  callsChip: { calls: [] },
  statutsAppelEmettre: [],
  contactsSousContrat: [],
  sousContrat: [],
  contacts: { contacts: [] },
  contactsNotAffected: [],
  contactsNotAffectedAlarme: [],
  contactsNotAffectedMobile: [],
  affectedContacts: [],
  affectedContactsAlarme: [],
  affectedContactsMobile: [],
  notesDynamiques: [],
  venteByID: {},
  pipelineLeads: [],
  pipelineLeadsMobile: [],

  sContrat: [],
  futureEcheance: [],
  futureBesoin: [],
  horsCible: [],

  sContratAlarme: [],
  futureEcheanceAlarme: [],
  futureBesoinAlarme: [],
  koBudgetAlarme: [],
  koAutoriteAlarme: [],
  koRefusAlarme: [],
  pasInteresseAlarme: [],
  koAutreAlarme: [],

  sContratMobile: [],
  futureEcheanceMobile: [],
  futureBesoinMobile: [],
  koBudgetMobile: [],
  koAutoriteMobile: [],
  koRefusMobile: [],
  pasInteresseMobile: [],
  koAutreMobile: [],

  pipelineLeadsAffected: [],
  pipelineLeadsAffectedMobile: [],

  rdvsOutDated: [],
  rdvsToDay: [],
  rdvsToMorrow: [],
  rdvsTwoDays: [],
  rdvsThreeDays: [],
  rdvsFourDays: [],
  rdvsFiveDays: [],
  rdvsOneMonth: [],
  rdvssixMonth: [],

  rdvsAboveFiveDays: [],

  rdvs: [],

  statutsRDV: [],
  statutsMobileRDV: [],
  callsForDisplay: { call: [], liste: [] ,listtotCallsOp:[]},
  researchJournal: [],
  demandeAffectation: [],
  salesForDisplayTv4:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CALL_ENDED:
      return {
        ...state,
        callEnded: action.payload,
      };
      return {
        ...state,
        failedUpload: action.payload,
      };

    case GET_CONTACTS_SOUS_CONTRAT_BY_ID:
      return {
        ...state,
        sousContrat: action.payload,
      };
    case GET_ACTIVITES:
      return {
        ...state,
        activites: action.payload,
      };

    case GET_APPEL_EMETTRE || UPDATE_DATE_APPEL_EMETTRE:
      return {
        ...state,
        appelAEmettresOutDated: action.payload.appelAEmettresOutDated,
        appelAEmettresToDay: action.payload.appelAEmettresToDay,
        appelAEmettresToMorrow: action.payload.appelAEmettresToMorrow,
        appelAEmettresTwoDays: action.payload.appelAEmettresTwoDays,
        appelAEmettresThreeDays: action.payload.appelAEmettresThreeDays,
        appelAEmettresFourDays: action.payload.appelAEmettresFourDays,
        appelAEmettresFiveDays: action.payload.appelAEmettresFiveDays,
        appelAEmettresOneMonth: action.payload.appelAEmettresOneMonth,
        appelAEmettresSixMonth: action.payload.appelAEmettresSixMonth,

        // appelAEmettresAboveFiveDays: action.payload.appelAEmettresAboveFiveDays,

        appelsEmettre: action.payload.appelAEmettres,
        appelAEmettresAssigned: action.payload.appelAEmettresAssigned,
        rdvCree: action.payload.rdvCree,
        appelAEmettresAssignedCategorised:
          action.payload.appelAEmettresAssignedCategorised,
        statutsAppelEmettre: action.payload.populatedContactStatus,
        statutsMobileAppelEmettre: action.payload.populatedContactStatusMobile,
      };
    case GET_RDV_AFFECTED_TO_USER:
      return {
        ...state,
        rdvsOutDated: action.payload.rdvsOutDated,
        rdvsToDay: action.payload.rdvsToDay,
        rdvsToMorrow: action.payload.rdvsToMorrow,
        rdvsTwoDays: action.payload.rdvsTwoDays,
        rdvsThreeDays: action.payload.rdvsThreeDays,
        rdvsFourDays: action.payload.rdvsFourDays,
        rdvsFiveDays: action.payload.rdvsFiveDays,
        rdvsOneMonth: action.payload.rdvsOneMonth,
        rdvssixMonth: action.payload.rdvssixMonth,
        // rdvsAboveFiveDays: action.payload.rdvsAboveFiveDays,

        rdvs: action.payload.rdvs,

        statutsRDV: action.payload.populatedContactStatus,
        statutsMobileRDV: action.payload.populatedContactStatusMobile,
      };

    case GET_RDV:
      return {
        ...state,
        rdvByUser: action.payload,
      };
    case GET_RDV_CREATED_BY_USER:
      return {
        ...state,
        rdvCreatedByUser: action.payload,
      };
    case GET_RDV_BY_CONTACT:
      return {
        ...state,
        rdvByContact: action.payload,
      };
    case ADD_RDV:
      return {
        ...state,
        rdvByContact: [action.payload, ...state.rdvByContact],
      };
    case UPDATE_RDV_BY_USER:
      return {
        ...state,
        rdvByUser: state.rdvByUser.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
                noteSup: action.payload.noteSup,
                noteTermine: action.payload.noteTermine,
                noteHistory: action.payload.noteHistory,
                noteAnnulation: action.payload.noteAnnulation,
                affected: action.payload.affected,
                etat: action.payload.etat,
                affectedTo: action.payload.affectedTo,
                important: action.payload.important,
                typeRDV: action.payload.typeRDV,
                etat: action.payload.etat,
                notified: action.payload.notified,
                affected: action.payload.affected,
              }
            : content
        ),
      };
    case UPDATE_RDV_BY_CONTACT:
      return {
        ...state,
        rdvByContact: state.rdvByContact.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
                noteSup: action.payload.noteSup,
                noteTermine: action.payload.noteTermine,
                noteHistory: action.payload.noteHistory,
                noteAnnulation: action.payload.noteAnnulation,
                affected: action.payload.affected,
                etat: action.payload.etat,
                affectedTo: action.payload.affectedTo,
                important: action.payload.important,
                typeRDV: action.payload.typeRDV,
                etat: action.payload.etat,
                notified: action.payload.notified,
                affected: action.payload.affected,
              }
            : content
        ),
      };

      case UPDATE_TIME_RDV:
      return {
        ...state,
        rdvByUser: state.rdvByUser.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
              }
            : content
        ),

        rdvByContact: state.rdvByContact.map((content) =>
        content._id === action.payload._id
          ? {
              ...content,
              dateAppel: action.payload.dateAppel,
            }
          : content
      ),

      };
    case GET_APPEL_EMETTRE_BY_CONTACT:
      return {
        ...state,
        appelsEmettreByContact: action.payload,
      };

    case ADD_APPEL_EMETTRE:
      return {
        ...state,
        appelsEmettreByContact: [
          action.payload,
          ...state.appelsEmettreByContact,
        ],
      };
    case DELETE_APPEL_EMETTRE:
      state.appelsEmettre.splice(
        state.appelsEmettre.findIndex((e) => e._id === action.payload),
        1
      );
      state.appelAEmettresOutDated.splice(
        state.appelAEmettresOutDated.findIndex((e) => e._id === action.payload),
        1
      );
      state.appelAEmettresToDay.splice(
        state.appelAEmettresToDay.findIndex((e) => e._id === action.payload),
        1
      );
      state.appelAEmettresToMorrow.splice(
        state.appelAEmettresToMorrow.findIndex((e) => e._id === action.payload),
        1
      );
      state.appelAEmettresTwoDays.splice(
        state.appelAEmettresTwoDays.findIndex((e) => e._id === action.payload),
        1
      );
      state.appelAEmettresThreeDays.splice(
        state.appelAEmettresThreeDays.findIndex(
          (e) => e._id === action.payload
        ),
        1
      );
      state.appelAEmettresFourDays.splice(
        state.appelAEmettresFourDays.findIndex((e) => e._id === action.payload),
        1
      );
      state.appelAEmettresFiveDays.splice(
        state.appelAEmettresFiveDays.findIndex((e) => e._id === action.payload),
        1
      );
      state.appelAEmettresOneMonth.splice(
        state.appelAEmettresOneMonth.findIndex((e) => e._id === action.payload),
        1
      );
      state.appelAEmettresSixMonth.splice(
        state.appelAEmettresSixMonth.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        appelsEmettre: state.appelsEmettre,
        appelAEmettresOutDated: state.appelAEmettresOutDated,
        appelAEmettresToDay: state.appelAEmettresToDay,
        appelAEmettresToMorrow: state.appelAEmettresToMorrow,
        appelAEmettresTwoDays: state.appelAEmettresTwoDays,
        appelAEmettresThreeDays: state.appelAEmettresThreeDays,
        appelAEmettresFourDays: state.appelAEmettresFourDays,
        appelAEmettresFiveDays: state.appelAEmettresFiveDays,
        appelAEmettresOneMonth:state.appelAEmettresOneMonth,
        appelAEmettresSixMonth:state.appelAEmettresSixMonth
        // appelAEmettresAboveFiveDays: state.appelAEmettresAboveFiveDays,
      };
    case DELETE_APPEL_EMETTRE_BY_CONTACT:
      state.appelsEmettreByContact.splice(
        state.appelsEmettreByContact.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        appelsEmettreByContact: state.appelsEmettreByContact,
      };
    // case UPDATE_APPEL_EMETTRE_NOTIFIED:
    //   state.appelAEmettresAssignedCategorised.splice(
    //     state.appelAEmettresAssignedCategorised.findIndex((e) => e._id === action.payload),
    //     1
    //   );
    //   return {
    //     ...state,
    //     appelAEmettresAssignedCategorised: state.appelAEmettresAssignedCategorised,
    //   };
    case GET_AFFECTED_CONTACTS:
      return {
        ...state,
        affectedContacts: action.payload,
      };
    case GET_AFFECTED_CONTACTS_ALARME:
      return {
        ...state,
        affectedContactsAlarme: action.payload,
      };

    case GET_AFFECTED_CONTACTS_MOBILE:
      return {
        ...state,
        affectedContactsMobile: action.payload,
      };
    case GET_SOUS_CONTRAT:
      return {
        ...state,
        sContrat: action.payload,
      };
    case GET_FUTURE_ECHEANCE:
      return {
        ...state,
        futureEcheance: action.payload,
      };
    case GET_FUTURE_BESOIN:
      return {
        ...state,
        futureBesoin: action.payload,
      };
    case GET_HORS_CIBLE:
      return {
        ...state,
        horsCible: action.payload,
      };

    case GET_SOUS_CONTRAT_ALARME:
      return {
        ...state,
        sContratAlarme: action.payload,
      };
    case GET_FUTURE_ECHEANCE_ALARME:
      return {
        ...state,
        futureEcheanceAlarme: action.payload,
      };
    case GET_FUTURE_BESOIN_ALARME:
      return {
        ...state,
        futureBesoinAlarme: action.payload,
      };
    case GET_KO_BUDGET_ALARME:
      return {
        ...state,
        koBudgetAlarme: action.payload,
      };
    case GET_KO_AUTORITE_ALARME:
      return {
        ...state,
        koAutoriteAlarme: action.payload,
      };
    case GET_KO_REFUS_ALARME:
      return {
        ...state,
        koRefusAlarme: action.payload,
      };
    case GET_PAS_INTERESSE_ALARME:
      return {
        ...state,
        pasInteresseAlarme: action.payload,
      };
    case GET_KO_AUTRE_ALARME:
      return {
        ...state,
        koAutreAlarme: action.payload,
      };

    case GET_SOUS_CONTRAT_MOBILE:
      return {
        ...state,
        sContratMobile: action.payload,
      };
    case GET_FUTURE_ECHEANCE_MOBILE:
      return {
        ...state,
        futureEcheanceMobile: action.payload,
      };
    case GET_FUTURE_BESOIN_MOBILE:
      return {
        ...state,
        futureBesoinMobile: action.payload,
      };
    case GET_KO_BUDGET_MOBILE:
      return {
        ...state,
        koBudgetMobile: action.payload,
      };

    case GET_KO_AUTORITE_MOBILE:
      return {
        ...state,
        koAutoriteMobile: action.payload,
      };

    case GET_KO_REFUS_MOBILE:
      return {
        ...state,
        koRefusMobile: action.payload,
      };
    case GET_PAS_INTERESSE_MOBILE:
      return {
        ...state,
        pasInteresseMobile: action.payload,
      };
    case GET_KO_AUTRE_MOBILE:
      return {
        ...state,
        koAutreMobile: action.payload,
      };



     // UPDATE_TIME_APPEL_EMETTRE

     case UPDATE_TIME_APPEL_EMETTRE:
      return {
        ...state,
        appelsEmettre: state.appelsEmettre.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
              }
            : content
        ),
        appelAEmettresOutDated: state.appelAEmettresOutDated.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
              }
            : content
        ),
        appelAEmettresToDay: state.appelAEmettresToDay.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
              }
            : content
        ),
        appelAEmettresToMorrow: state.appelAEmettresToMorrow.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
              }
            : content
        ),
        appelAEmettresTwoDays: state.appelAEmettresTwoDays.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
              }
            : content
        ),
        appelAEmettresThreeDays: state.appelAEmettresThreeDays.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                dateAppel: action.payload.dateAppel,
              }
            : content
        ),
        appelAEmettresFourDays: state.appelAEmettresFourDays.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                dateAppel: action.payload.dateAppel,
              
              }
            : content
        ),
        appelAEmettresFiveDays: state.appelAEmettresFiveDays.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
                
              }
            : content
        ),
        appelAEmettresOneMonth: state.appelAEmettresOneMonth.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
              }
            : content
        ),
        appelAEmettresSixMonth: state.appelAEmettresSixMonth.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
              }
            : content
        ),
       
      };
   














    case UPDATE_APPEL_EMETTRE:
      return {
        ...state,
        appelsEmettre: state.appelsEmettre.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresOutDated: state.appelAEmettresOutDated.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresToDay: state.appelAEmettresToDay.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresToMorrow: state.appelAEmettresToMorrow.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresTwoDays: state.appelAEmettresTwoDays.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresThreeDays: state.appelAEmettresThreeDays.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresFourDays: state.appelAEmettresFourDays.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresFiveDays: state.appelAEmettresFiveDays.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresOneMonth: state.appelAEmettresOneMonth.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
        appelAEmettresSixMonth: state.appelAEmettresSixMonth.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                dateAppel: action.payload.dateAppel,
                note: action.payload.note,
                important: action.payload.important,
                raison: action.payload.raison,
                creatorId: action.payload.creatorId,
                contactId: action.payload.contactId,
                typeAppel: action.payload.typeAppel,
                typeRDV: action.payload.typeRDV,
                approche: action.payload.approche,
                dateFin: action.payload.dateFin,
                assignedId: action.payload.assignedId,
                etat: action.payload.etat,
              }
            : content
        ),
       
      };
    case UPDATE_APPEL_EMETTRE_BY_CONTACT:
      return {
        ...state,
        appelsEmettreByContact: state.appelsEmettreByContact
          .map((content) =>
            content._id === action.payload._id
              ? {
                  ...content,

                  dateAppel: action.payload.dateAppel,
                  note: action.payload.note,
                  important: action.payload.important,
                  raison: action.payload.raison,
                  creatorId: action.payload.creatorId,
                  contactId: action.payload.contactId,
                  typeAppel: action.payload.typeAppel,
                  typeRDV: action.payload.typeRDV,
                  approche: action.payload.approche,
                  dateFin: action.payload.dateFin,

                  etat: action.payload.etat,
                }
              : content
          )
          .filter((x) => x.etat === "en cours"),
      };
    case GET_ETAPES:
      return {
        ...state,
        etapes: action.payload,
      };
    case GET_CALLS:
      return {
        ...state,
        calls: action.payload,
      };
    case GET_CALLS_FOR_DISPLAY:
      return {
        ...state,
        callsForDisplay: action.payload,
      };

    case GET_SALES_FOR_DISPLAY:
      return{
        ...state,
        salesForDisplayTv4:action.payload

      }

    case GET_CALL_ID:
      return {
        ...state,
        updatedCall: action.payload,
      };
    case GET_CALLS_CHIP:
      return {
        ...state,
        callsChip: action.payload,
      };
    case GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };

  case GET_CONTACTS_HORSCIRCUIT_ALARME:
    return{
      ...state,
      contactsHorsCircuit:action.payload
    }
    case UPDATE_CONTACTS_HORSCIRCUIT_ALARME:
      return{
        ...state,
        contactsHorsCircuit:state.contactsHorsCircuit.filter(el=>el._id !==action.payload._id)}
      
    case GET_CONTACTS_NOT_AFFECTED:
      return {
        ...state,
        contactsNotAffected: action.payload,
      };
    case GET_CONTACTS_NOT_AFFECTED_ALARME:
      return {
        ...state,
        contactsNotAffectedAlarme: action.payload,
      };
    case GET_CONTACTS_NOT_AFFECTED_MOBILE:
      return {
        ...state,
        contactsNotAffectedMobile: action.payload,
      };
    case GET_VENTES:
      return {
        ...state,
        ventes: action.payload,
      };
    case UPDATE_ETAPE:
      return {
        ...state,
        etapes: state.etapes.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                libelle: action.payload.libelle,
                valideurs: action.payload.valideurs,
                lecteurs: action.payload.lecteurs,
                modificateurs: action.payload.modificateurs,
              }
            : content
        ),
      };

    case GET_NOTES:
      return {
        ...state,
        notesDynamiques: action.payload,
      };

    case UPDATE_NOTE:
      return {
        ...state,
        notesDynamiques: state.notesDynamiques.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                content: action.payload.content,
                libelle: action.payload.libelle,
              }
            : content
        ),
      };

    case ADD_NOTE:
      return {
        ...state,
        notesDynamiques: [action.payload, ...state.notesDynamiques],
        // notesDynamiques: [ ...state.notesDynamiques],
      };
    case DELETE_NOTE:
      state.notesDynamiques.splice(
        state.notesDynamiques.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        notesDynamiques: state.notesDynamiques,
      };

    case GET_VENTE_BY_ID:
      return {
        ...state,
        venteByID: action.payload,
      };

    // PipeLine
    case GET_PIPELINE_CONTACT:
      return {
        ...state,
        pipelineLeads: action.payload,
      };
    case GET_PIPELINE_CONTACT_BY_USER:
      return {
        ...state,
        pipelineLeads: action.payload,
      };
    case GET_PIPELINE_CONTACT_MOBILE:
      return {
        ...state,
        pipelineLeadsMobile: action.payload,
      };
    case GET_PIPELINE_CONTACT_MOBILE_BY_USER:
      return {
        ...state,
        pipelineLeadsMobile: action.payload,
      };
    case MODIFY_STATUT_PIPELINE:
      return {
        ...state,

        pipelineLeads: state.pipelineLeads.map((content) =>
          content.adresseId._id === action.payload.adresseId
            ? {
                ...content,
                pipelineLeads: [action.payload, ...content.pipelineLeads],
              }
            : content
        ),
      };
    case MODIFY_STATUT_PIPELINE_MOBILE:
      return {
        ...state,

        pipelineLeadsMobile: state.pipelineLeadsMobile.map((content) =>
          content.contactId._id === action.payload.contactId
            ? {
                ...content,
                pipelineLeadsMobile: [
                  action.payload,
                  ...content.pipelineLeadsMobile,
                ],
              }
            : content
        ),
      };
    case GET_PIPELINE_CONTACT_AFFECTED:
      return {
        ...state,
        pipelineLeadsAffected: action.payload,
      };
    case GET_PIPELINE_CONTACT_AFFECTED_BY_USER:
      return {
        ...state,
        pipelineLeadsAffected: action.payload,
      };
    case GET_PIPELINE_CONTACT_AFFECTED_MOBILE:
      return {
        ...state,
        pipelineLeadsAffectedMobile: action.payload,
      };
    case GET_PIPELINE_CONTACT_AFFECTED_MOBILE_BY_USER:
      return {
        ...state,
        pipelineLeadsAffectedMobile: action.payload,
      };

    case GET_RH_TIMELINE:
      return {
        ...state,
        RHTimeline: action.payload,
      };

    case ACCEPT_AFFECTED_CONTACT:
      return {
        ...state,
        affectedContacts: state.affectedContacts.map((content) =>
          content._id === action.payload.populatedContact._id
            ? {
                ...content,
                lastElemAlarme: action.payload.lastElemAlarme,
                lastElemMobile: action.payload.lastElemMobile,
              }
            : content
        ),
      };
    case GET_RESEARCH_JOURNAL:
      return {
        ...state,
        researchJournal: action.payload,
      };
    case GET_DEMANDE_AFFECTAION:
      return {
        ...state,
        demandeAffectation: action.payload,
      };
    case UPDATE_DEMANDE_AFFECTAION:
      return {
        ...state,

        demandeAffectation: state.demandeAffectation.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,

                etat: action.payload.etat,
              }
            : content
        ),
      };
    default:
      return state;
  }
}
