import {
  GET_COMPAGNIES,
  ADD_COMPAGNIE,
  UPDATE_COMPAGNIE,
} from "../actions/types";

const initialState = {
  compagnies: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPAGNIES:
      return {
        ...state,
        compagnies: action.payload,
      };
    case ADD_COMPAGNIE:
      return {
        ...state,
        compagnies: [action.payload, ...state.compagnies],
      };
    case UPDATE_COMPAGNIE:
      return {
        ...state,
        compagnies: state.compagnies.map((content) =>
          content._id === action.payload.id
            ? {
                ...content,
                nom: action.payload.nom,
                adresse: action.payload.adresse,
                email: action.payload.email,
                telephone: action.payload.telephone,
                actif: action.payload.actif,
                approche:action.payload.approche
              }
            : content
        ),
      };
    default:
      return state;
  }
}
