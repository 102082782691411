import {
    GET_ACTIVITIES_USER
  } from "../../actions/types";
  
  const initialState = {
      userActivities:[],
  }
  
  const rhReducer = (state=initialState,action)=>{
  switch(action.type){
      case GET_ACTIVITIES_USER:
        return{
          ...state,
          userActivities:action.payload
        }
      default:return state
  }
  }
  export default rhReducer 