import {
    ADD_NOTIFICATION,
    UPDATE_NOTIFICATION,
    GET_NOTIFICATION_BY_USER,
    GET_NOTIFICATION_BY_SOURCE, // POUR FAIRE DES ALERTES LORS DE L4ADDITION D'UN CONTACT SOUMISSION
  } from "../../actions/types";
  
  const initialState = {
    notificationByUser: [],
    numberNotificationNotRead: 0,
  };
  
  const boiteNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_NOTIFICATION:
        return {
          ...state,
          notificationByUser: [...state.notificationByUser, action.payload],
          numberNotificationNotRead: [
            ...state.notificationByUser,
            action.payload,
          ].filter((el) => el.dejaVu === false).length,
        };
  
      case GET_NOTIFICATION_BY_USER:
        return {
          ...state,
          notificationByUser: action.payload,
          numberNotificationNotRead: action.payload.filter(
            (el) => el.dejaVu === false
          ).length,
        };
  
      case UPDATE_NOTIFICATION:
        return {
          ...state,
          notificationByUser: state.notificationByUser.map((el) =>
            el._id === action.payload._id
              ? {
                  ...el,
                  dejaVu: action.payload.dejaVu,
                }
              : el
          ),
          numberNotificationNotRead : state.notificationByUser.map((el) =>
            el._id === action.payload._id
              ? {
                  ...el,
                  dejaVu: action.payload.dejaVu,
                }
              : el
          ).filter(
            (el) => el.dejaVu === false
          ).length
        };
  
      default:
        return state;
    }
  };
  
  export default boiteNotificationReducer;
  