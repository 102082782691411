import {
  ADD_TYPE_SCRIPT,
  GET_ALL_TYPE_SCRIPT,
  UPDATE_TYPE_SCRIPT_APPEL,
  ADD_QUESTION_SCRIPT_APPEL,
  UPDATE_QUESTION_SCRIPT_APPEL,
  GET_ALL_QUSTIONS_SCRIPT,
  ADD_SCRIPT,
  UPDATE_SCRIPT,
  DELETE_SCRIPT,
  GET_ALL_SCRIPTS,
  GET_SCRIPT_By_APPROCHE_TYPESCRIPT_SECTTEURDACTIVITE
} from "../../actions/types";

const initialState = {
    typeScriptAppel:[],
    questionScriptAppel:[],
    scriptAppel:[],
    contentScriptAppel:[]
}

const scriptAppelReducer = (state=initialState,action)=>{
switch(action.type){
  /// CASE TYPE SCRIPT APPEL
    case ADD_TYPE_SCRIPT:
      return{
        ...state,
        typeScriptAppel:[...state.typeScriptAppel,action.payload]
      }
      case GET_ALL_TYPE_SCRIPT:
        return{
          ...state,
          typeScriptAppel:action.payload
        }
          case UPDATE_TYPE_SCRIPT_APPEL:
            return{
              ...state,
              typeScriptAppel:state.typeScriptAppel.map(el=>
                el._id===action.payload._id
                ? 
                  {...el,
                  description:action.payload.description,
                  active:action.payload.active,
                  }
                :el)
            }

     /// CASE QUESTION SCRIPT APPEL

     case ADD_QUESTION_SCRIPT_APPEL:
      return{
        ...state,
        questionScriptAppel:[...state.questionScriptAppel,action.payload]
      }
      case GET_ALL_QUSTIONS_SCRIPT:
        return{
          ...state,
          questionScriptAppel:action.payload
        }

        case UPDATE_QUESTION_SCRIPT_APPEL:
            return{
              ...state,
              questionScriptAppel:state.questionScriptAppel.map(el=>
                el._id===action.payload._id
                ? 
                  {...el,
                    question:action.payload.question,
                    answer:action.payload.answer,
                    active:action.payload.active
                    
                  }
                :el)
            }
     /// CASE SCRIPT APPEL

     case GET_SCRIPT_By_APPROCHE_TYPESCRIPT_SECTTEURDACTIVITE:
      return{
          ...state,
          contentScriptAppel:action.payload.content
      }
      case ADD_SCRIPT:
          return{
              ...state,
              scriptAppel:[...state.scriptAppel,action.payload]
          }

      case GET_ALL_SCRIPTS:
          return{
              ...state,
             scriptAppel:action.payload
              }    

      case UPDATE_SCRIPT:
         return{
           ...state,
           scriptAppel:state.scriptAppel.map(script=>
              script._id===action.payload._id
              ? {
                  ...script,
                  active:action.payload.active,
                  approche:action.payload.approche,
                  content:action.payload.content,
                  secteurActivite:action.payload.secteurActivite,
              }:script
              )
           }

           case DELETE_SCRIPT:
         return{
           ...state,
           scriptAppel:state.scriptAppel.filter(script=>script._id!=action.payload.id)}

    default:return state
}
}
export default scriptAppelReducer 