import {
    ADD_TEMPLATE_EMAIL,
    GET_ALL_TEMPLATE_EMAIL,
    UPDATE_TEMPLATE_EMAIL  
} from "../../actions/types"

const initialState = {
    allTemplatesEmail :[],
}


const templatesEmailReducer = (state=initialState,action)=>{
   switch (action.type) {
    case GET_ALL_TEMPLATE_EMAIL:
    return  {
    ...state,
    allTemplatesEmail:action.payload
    }  
    case UPDATE_TEMPLATE_EMAIL:
    
        return {
            ...state,
     allTemplatesEmail: state.allTemplatesEmail.map(el=>{
     return (  el._id === action.payload._id ?
       {
            ...el,
            active: action.payload.active
        }
        :
        el)
     })                                                             
        } 
 case ADD_TEMPLATE_EMAIL:
    return{
        ...state,
        allTemplatesEmail:[...state.allTemplatesEmail,action.payload]
    }
    default : return state
   } 
}
export default templatesEmailReducer