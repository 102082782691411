import el from "date-fns/locale/el";

const initialState = {
  forfaits: [],
};

const forfaitReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_FORFAIT":
      return {
        ...state,
        forfaits: [...state.forfaits, action.payload],
      };

    case "GET_ALL_FORFAITS":
      return {
        ...state,
        forfaits: action.payload,
      };
    case "UPDATE_FORFAIT":
      return {
        ...state,
        forfaits: state.forfaits.map((el) =>
          el._id === action.payload._id
            ? {
                ...el,
                nomForfait: action.payload.nomForfait,
                active: action.payload.active,
                prix: action.payload.prix,
                fraisActivation: action.payload.fraisActivation,
                type: action.payload.type,
                compagnieId: action.payload.compagnieId,
                fraisInstallation: action.payload.fraisInstallation,
                rabais60Moins: action.payload.rabais60Moins,
                rabaisClientMobile: action.payload.rabaisClientMobile,
                rabaisClientInternet: action.payload.rabaisClientInternet,
                rabaisClientMobileAffaire:
                  action.payload.rabaisClientMobileAffaire,
                rabaisDoubleService: action.payload.rabaisDoubleService,
                rabaisBackPocket: action.payload.rabaisBackPocket,
                rabaisAutre: action.payload.rabaisAutre,
                MoisGratuit: action.payload.MoisGratuit,
                equipements: action.payload.equipements,
                equipementExtra: action.payload.equipementExtra,
                creditBienvenue: action.payload.creditBienvenue,
                typeAppel: action.payload.typeAppel,
                numberGB: action.payload.numberGB,
                typeAppel: action.payload.typeAppel,
                vitesse: action.payload.vitesse,
                fraisShipping: action.payload.fraisShipping,
                numberAppareilsConnectes:
                  action.payload.numberAppareilsConnectes,
                categoryForfait: action.payload.categoryForfait,
                typeLigne: action.payload.typeLigne,
                mensualitePhoneIp: action.payload.mensualitePhoneIp,
                fraisTransfertPhoneIp: action.payload.fraisTransfertPhoneIp,

                rabaisDeuxLignes:action.payload.rabaisDeuxLignes,
                rabaisTroisLignes:action.payload.rabaisTroisLignes,
                rabaisquatresLignes:action.payload.rabaisquatresLignes,
                rabaisMultiLignes:action.payload.rabaisMultiLignes,
                rabaisDeTransfert:action.payload.rabaisDeTransfert,
                rabiasClientAlarme:action.payload.rabiasClientAlarme,
                rabaisClientInternet:action.payload.rabaisClientInternet,
                rabaisClientIp:action.payload.rabaisClientIp,
                autreRabais:action.payload.autreRabais,

                addRabiasClientAlarme:action.payload.addRabiasClientAlarme,
                addRabaisClientInternet:action.payload.addRabaisClientInternet,
                addRabaisClientIp:action.payload.addRabaisClientIp,
                addAutreRabais:action.payload.addAutreRabais,
                descriptionVente:action.payload.descriptionVente,
              }
            : el
        ),
      };
    default:
      return state;
  }
};
export default forfaitReducer;
