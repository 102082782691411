import {
    GET_SOUMISSIONS,
    GET_ACTIVITIES,
  } from "../actions/types";
  
  const initialState = {
   
    soumissionsList: [],
    activities: [],
    
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_SOUMISSIONS:
        return {
          ...state,
          soumissionsList: action.payload,
          
        };
      case GET_ACTIVITIES:
        return {
          ...state,
          activities: action.payload,
          
        };

        default:
            return state;
        }
      }