import {
    GET_CONTRATS,
    
  } from "../actions/types";
  
  const initialState = {
    contrats: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_CONTRATS:
        return {
          ...state,
          contrats: action.payload,
        };
    
      default:
        return state;
    }
  }
  