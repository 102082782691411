import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import background from "./img.svg";
import background2 from "./img2.jpg";

import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  CardImg,
  Col,
  Container,
  Form,
  Input,
  Row,
  FormFeedback,
  FormGroup,
} from "reactstrap";
const style2 = {
  backgroundColor: "#FF8D29",
  color: "#ffffff",
};
class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }
  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      if (nextProps.auth.isAuthenticated) {
        this.props.history.push("/"); // push user to dashboard when they login
      }
      if (nextProps.errors) {
        this.setState({
          errors: nextProps.errors,
        });
      }
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };
  render() {
    const { errors } = this.state;
    return (
      <div
        className="app flex-row align-items-center"
        style={{
          backgroundImage: `url(${background2})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card
                  body
                  color="warning"
                  outline
                  className="p-4"
                  style={{
                    backgroundImage: `url(${background2})`,
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {" "}
                  <CardImg
                    alt="Card image cap"
                    src={background}
                    top
                    width="100%"
                  />
                  <CardBody>
                    <Form noValidate onSubmit={this.onSubmit}>
                      <h3>Login</h3>
                      <p className="text-muted">
                        Connectez-vous à votre compte
                      </p>
                      <FormGroup className="mb-3">
                        <Input
                          autoComplete="email"
                          placeholder="email"
                          onChange={this.onChange}
                          value={this.state.email}
                          id="email"
                          type="email"
                          invalid={false}
                          className={classnames("", {
                            invalid: errors.email || errors.emailnotfound,
                          })}
                        />
                        <FormFeedback>
                          {errors.email}
                          {errors.emailnotfound}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          placeholder="password"
                          autoComplete="current-password"
                          onChange={this.onChange}
                          value={this.state.password}
                          id="password"
                          type="password"
                          className={classnames("", {
                            invalid:
                              errors.password || errors.passwordincorrect,
                          })}
                        />
                        <FormFeedback>
                          {errors.password}
                          {errors.passwordincorrect}
                        </FormFeedback>
                      </FormGroup>
                      <Row style={{ textAlign: "right" }}>
                        <Col>
                          <Button style={style2} className="px-4 text-right">
                            Login
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  loginUser,
})(Login);
