import {
  GET_CALL_HISTORY,
  GET_CONTACT,
  GET_PUBLIC_NOTES,
  GET_PRIVATE_NOTES,
  ADD_PRIVATE_NOTE,
  DELETE_PRIVATE_NOTE,
  ADD_PUBLIC_NOTE,
  DELETE_PUBLIC_NOTE,
  GET_CONTACT_NUMBERS,
  GET_CONTACT_STATUT,
  ADD_CONTACT_STATUT,
  UPDATE_CONTACT_STATUT,
  GET_CONTACT_STATUT_MOBILE,
  ADD_CONTACT_STATUT_MOBILE,
  UPDATE_CONTACT_STATUT_MOBILE,
  GET_CONTACT_ADDRESS,
  ADD_SUIVI_STAND_BY,
  UPDATE_CONTACT,
  ADD_NUMBER,
  DELETE_NUMBER,
  ADD_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,

  GET_CONTACT_STATUT_MOBILE_BY_USER,
  GET_CONTACT_STATUT_BY_USER,
  UPDATE_FICHE_CONTACT,
  // GET_APPEL_EMETTRE_BY_CONTACT,
  GET_NOTES_ALL_CONTACTS,
  GET_ACTIVITES_BY_CONTACT,
  GET_SUIVI_BY_CONTACT,

  GET_HISTORIQUE_AFFECTATION,
  GET_CONTACT_PARRAINAGE,
  LOADING_STARTED_CONTACT_STATE,

  UPDATE_CALL_STATUS,
  UPDATE_NUMBER,
  // GET_RDV_BY_CONTACT,
} from "../actions/types";
import isEmpty from "is-empty";
const initialState = {
  reset: true,
  contact: {},
  contacts: [],

  listParrainage: [],
  rdvByContact: [],
  statuts: [],
  statutsMobile: [],
  pipelineLeads: [],
  pipelineLeadsMobile: [],
  numeros: [],
  adresses: [],
  history: [],
  public: [],
  private: [],
  // appelsEmettre: [],
  suiviStandBy: {},
  notesAllContacts: [],
  activites: [],
  prospectionsCommerciale: [],
historiqueAffectation: [],
// chayma 
fichePdf:[],
ficheImage:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    // case GET_RDV_BY_CONTACT:
    //   return {
    //     ...state,
    //     rdvByContact: action.payload
    //   };

    case LOADING_STARTED_CONTACT_STATE:
      return {
        ...state,
        loading: true,
      };
    case LOADING_STARTED_CONTACT_STATE:
      return {
        ...state,
        loading: true,
      };
    case GET_SUIVI_BY_CONTACT:
      return {
        ...state,
        prospectionsCommerciale: action.payload,
      };
    case GET_ACTIVITES_BY_CONTACT:
      return {
        ...state,
        activites: action.payload,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };

    //  chayma
    case UPDATE_FICHE_CONTACT:
      return {
        ...state,
        contact: { ...state.contact,
           ficheText: action.payload.ficheText, 
           fichePdf: [...state.fichePdf,action.payload.fichePdf],
           ficheImage:[...state.ficheImage,action.payload.ficheImage]
          }

      };


    // case GET_APPEL_EMETTRE_BY_CONTACT:  
    //   return {
    //     ...state,
    //     appelsEmettre: action.payload,
    //   };
    case GET_CONTACT_PARRAINAGE:
      return {
        ...state,
        listParrainage: action.payload,
      };
    case GET_CONTACT_STATUT:
      return {
        ...state,
        statuts: action.payload,
      };
    case GET_CONTACT_STATUT_MOBILE:
      return {
        ...state,
        statutsMobile: action.payload,
      };
    case GET_CONTACT_STATUT_BY_USER:
      return {
        ...state,
        pipelineLeads: action.payload,
      };
    case GET_CONTACT_STATUT_MOBILE_BY_USER:
      return {
        ...state,
        pipelineLeadsMobile: action.payload,
      };

    case GET_CALL_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case UPDATE_NUMBER:
      return {
        ...state,
        numeros: state.numeros.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                number: action.payload.number,
                description: action.payload.description,
              }
            : content
        ),
      };
    case UPDATE_CALL_STATUS:
      const index2 = state.history.findIndex(
        (el) => el.number === action.payload.numero.number
      );

      return {
        ...state,
        history: [
          ...state.history,
          (state.history[index2].history = state.history[index2].history.map(
            (element) =>
              element._id === action.payload._id
                ? { ...element,
                   statutAppel: action.payload.statutAppel,
                   approche: action.payload.approche 
                   
                  }
                : element
          )),
        ],
      };

    // return {
    //   ...state,
    //   history: [
    //     ...state.history,
    //     state.history[index2].history =
    //        state.history[index2].history.map(
    //       (element) =>
    //         element._id === action.payload._id
    //           ? { ...element, statutAppel: action.payload.statutAppel }
    //           : element
    //     )
    //   ],
    // };

    case GET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
 


    case GET_HISTORIQUE_AFFECTATION:
      return {
        ...state,
        historiqueAffectation: action.payload,
      };
    case GET_CONTACT_NUMBERS:
      return {
        ...state,
        numeros: action.payload,
      };
    case GET_CONTACT_ADDRESS:
      return {
        ...state,
        adresses: action.payload,
      };
    case GET_PUBLIC_NOTES:
      return {
        ...state,
        public: action.payload,
      };
    case GET_PRIVATE_NOTES:
      return {
        ...state,
        private: action.payload,
      };
    case GET_NOTES_ALL_CONTACTS:
      return {
        ...state,
        notesAllContacts: action.payload,
      };
    case ADD_PRIVATE_NOTE:
      return {
        ...state,
        private: [action.payload, ...state.private],
      };
    case DELETE_PRIVATE_NOTE:
      state.private.splice(
        state.private.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        private: state.private,
      };
    case ADD_PUBLIC_NOTE:
      return {
        ...state,
        public: [action.payload, ...state.public],
      };
    case DELETE_PUBLIC_NOTE:
      state.public.splice(
        state.public.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        public: state.public,
      };
    case ADD_SUIVI_STAND_BY:
      return {
        ...state,
        suiviStandBy: action.payload,
      };
    case ADD_NUMBER:
      return {
        ...state,
         numeros: [...state.numeros, { number: action.payload.number ,description:action.payload.description}],
      };

    case DELETE_NUMBER:
      state.numeros.splice(
        state.numeros.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        numeros: state.numeros,
      };

    case ADD_ADDRESS:
      return {
        ...state,
        adresses: [...state.adresses, action.payload],
      };
    case ADD_CONTACT_STATUT:
      return {
        ...state,
        statuts: state.statuts.map((content) =>
          content.adresseId._id === action.payload.adresseId
            ? {
                ...content,
                statuts: [action.payload, ...content.statuts],
              }
            : content
        ),
        pipelineLeads: state.pipelineLeads.map((content) =>
          content.adresseId._id === action.payload.adresseId
            ? {
                ...content,
                pipelineLeads: [action.payload, ...content.pipelineLeads],
              }
            : content
        ),
      };
    case UPDATE_CONTACT_STATUT:
      // console.log(action.payload);
      const index = state.statuts.findIndex(
        (element) => element.adresseId._id === action.payload.adresse._id
      );
      if (index !== -1)
        state.statuts[index].statuts.unshift(action.payload.statuts);
      else
        state.statuts.push({
          adresseId: action.payload.adresse,
          statuts: [action.payload.statuts],
        });
      return {
        ...state,
      };
    case UPDATE_CONTACT_STATUT_MOBILE:
      state.statutsMobile.push({
        // contactId: action.payload.contact,
        statuts: [action.payload.statuts],
      });
      return {
        ...state,
      };
    case ADD_CONTACT_STATUT_MOBILE:
      return {
        ...state,
        statutsMobile: state.statutsMobile.map((content) =>
          content.contactId._id === action.payload.contactId
            ? {
                ...content,
                statuts: [action.payload, ...content.statuts],
              }
            : content
        ),
        pipelineLeadsMobile: state.pipelineLeadsMobile.map((content) =>
          content.contactId._id === action.payload.contactId
            ? {
                ...content,
                pipelineLeadsMobile: [
                  action.payload,
                  ...content.pipelineLeadsMobile,
                ],
              }
            : content
        ),
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        adresses: state.adresses.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                rue: action.payload.rue,
                region: action.payload.region,
                ville: action.payload.ville,
                province: action.payload.province,
                codePostal: action.payload.codePostal,
                description: action.payload.description,
              }
            : content
        ),
      };
    case DELETE_ADDRESS:
      state.adresses.splice(
        state.adresses.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        adresses: state.adresses,
      };

    default:
      return state;
  }
}
