import Swal from "sweetalert2";
import {Alert} from "antd"
 
export const successAlertAntd = (numero)=>{
  
    <Alert
    type="success" 
    message="success"
    description="Affectation accept�e"
    closable
    />
  
}
export const successAlertWithoutTime = (numero) => {
  return Swal.fire({
    title: "SUCCESS!",
   // text: numero,
    icon: "success",
    html: numero ,
    confirmButtonText: "Cool",
  });
};
export const successAlert = (numero) => {
  return Swal.fire({
    title: "SUCCESS!",
    text: numero,
    icon: "success",
    timer: 3000,
    confirmButtonText: "Cool",
  });
};
export const errorAlert = (numero) => {
  return Swal.fire({
    title: "Error!",
    text: numero,
    icon: "error",
    // timer: 3000,
    confirmButtonText: "Ok",
  });
};

export const uploadErrorAlert = (numero) => {
  return Swal.fire({
    title: "SUCCESS!",
    text: numero,
    icon: "success",
    timer: 3000,
    confirmButtonText: "Cool",
  });
};