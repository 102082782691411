import { combineReducers } from "redux";
import authReducer from "./authReducers";
import contactReducer from "./contactReducer";
import produitReducer from "./produitReducer";
import venteReducer from "./venteReducer";
import vente2Reducer from "./vente2Reducer";
import errorReducer from "./errorReducers";
import dataReducer from "./dataReducer";
import compagnieReducer from "./compagnieReducer";
import contratReducer from "./contratReducer";
import soumissionsReducer from "./soumissionsReducer";
import scriptAppelReducer from "./scriptAppel/scriptAppelReducer";
import rhReducer from "./rh/rhReducer";
import forfaitReducer from "./forfaitReducer";
import templatesEmailReducer from "./emails/templateEmailReducer";
import boiteNotificationReducer from "./boiteNotification/boiteNotification";

const combinedReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  contact: contactReducer,
  products: produitReducer,
  ventes: venteReducer,
  vente2: vente2Reducer,
  data: dataReducer,
  compagnie: compagnieReducer,
  contrats: contratReducer,
  soumissions: soumissionsReducer,
  scriptAppel:scriptAppelReducer,
  forfaits:forfaitReducer,
  rh:rhReducer,
  templatesEmail:templatesEmailReducer,
  boiteNotification:boiteNotificationReducer
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
