import {
  GET_COMMANDES,
  ADD_COMMANDE,
  UPDATE_COMMANDE,
  DELETE_COMMANDE,
  UPDATE_COMMANDE_STATUS,
  GET_SUIVI_STAND_BY,
  DELETE_SUIVI_STAND_BY,
  UPDATE_SUIVI_STAND_BY,
  GET_CONFIRMATION_SUIVI,
  DELETE_CONFIRMATION_SUIVI,
  UPDATE_CONFIRMATION_SUIVI,
  GET_ENQUETE_CREDIT,
  DELETE_ENQUETE_CREDIT,
  UPDATE_ENQUETE_CREDIT,
  GET_CONFIRMATION_RDV_INSTALLATION,
  DELETE_CONFIRMATION_RDV_INSTALLATION,
  UPDATE_CONFIRMATION_RDV_INSTALLATION,
  GET_VENTE_NIVEAU_INSTALLATEUR,
  DELETE_VENTE_NIVEAU_INSTALLATEUR,
  UPDATE_VENTE_NIVEAU_INSTALLATEUR,
  GET_INSTALLATEURS,
  GET_VENTES_TERMINEES,
  DELETE_VENTES_TERMINEES,
  GET_VENTES_ANNULEES,
  GET_VENTES_CORBEILLE,
  GET_VENTES_CHARGE_BACK,
  UPDATE_PRIVILEGES,
  GET_KO_CREDIT,
  GET_KO_INSTALLATION,
} from "../actions/types";

const initialState = {
  valideur: false,
  modificateur: false,
  suivisStandBy: [],
  confirmationSuivis: [],
  enqueteCredit: [],
  koCredit: [],
  annulees: [],
  corbeilleVentes: [],
  chargeBack: [],
  koInstallation: [],
  confirmationRDVInstallation: [],
  venteNiveauInstallateur: [],
  ventesTerminees: [],
  commandes: [],
  listInstlateurs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PRIVILEGES:
      return {
        ...state,
        valideur: action.payload.valideur,
        modificateur: action.payload.modificateur,
      };
    case GET_VENTES_TERMINEES:
      return {
        ...state,
        ventesTerminees: action.payload,
      };
    case DELETE_VENTES_TERMINEES:
      state.ventesTerminees.splice(
        state.ventesTerminees.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        enqueteCredit: state.enqueteCredit,
      };
    case GET_INSTALLATEURS:
      return {
        ...state,
        listInstlateurs: action.payload,
      };
    case GET_SUIVI_STAND_BY:
      return {
        ...state,
        suivisStandBy: action.payload,
      };
    case DELETE_SUIVI_STAND_BY:
     
      state.suivisStandBy.splice(
        state.suivisStandBy.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        suivisStandBy: state.suivisStandBy,
      };
    case UPDATE_SUIVI_STAND_BY:
      return {
        ...state,
        suivisStandBy: state.suivisStandBy.map((content) =>
          content._id === action.payload._id
            ? {
              ...content,
              total: action.payload.total,
              venteAlarmeId: action.payload.venteAlarmeId,
              venteMobileId: action.payload.venteMobileId,
              venteInternetId: action.payload.venteInternetId,
              natureVente: action.payload.natureVente,
              operateurId: action.payload.operateurId,
              addressId: action.payload.addressId,
              contactId: action.payload.contactId,
              etapeId: action.payload.etapeId,
              historiqueFiche: action.payload.historiqueFiche,
              }
            : content
        ),
      };
    case GET_CONFIRMATION_SUIVI:
      return {
        ...state,
        confirmationSuivis: action.payload,
      };
    case DELETE_CONFIRMATION_SUIVI:
      state.confirmationSuivis.splice(
        state.confirmationSuivis.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        confirmationSuivis: state.confirmationSuivis,
      };
    case UPDATE_CONFIRMATION_SUIVI:
      return {
        ...state,
        confirmationSuivis: state.confirmationSuivis.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                total: action.payload.total,
                venteAlarmeId: action.payload.venteAlarmeId,
                venteMobileId: action.payload.venteMobileId,
                venteInternetId: action.payload.venteInternetId,
                natureVente: action.payload.natureVente,
                operateurId: action.payload.operateurId,
                addressId: action.payload.addressId,
                contactId: action.payload.contactId,
                etapeId: action.payload.etapeId,
                historiqueFiche: action.payload.historiqueFiche,
              }
            : content
        ),
      };
    case GET_ENQUETE_CREDIT:
      return {
        ...state,
        enqueteCredit: action.payload,
      };
    case GET_KO_CREDIT:
      return {
        ...state,
        koCredit: action.payload,
      };
    case GET_VENTES_ANNULEES:
      return {
        ...state,
        annulees: action.payload,
      };
    case GET_VENTES_CORBEILLE:
      return {
        ...state,
        corbeilleVentes: action.payload,
      };
    case GET_VENTES_CHARGE_BACK:
      return {
        ...state,
        chargeBack: action.payload,
      };
    case GET_KO_INSTALLATION:
      return {
        ...state,
        koInstallation: action.payload,
      };
    case DELETE_ENQUETE_CREDIT:
      state.enqueteCredit.splice(
        state.enqueteCredit.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        enqueteCredit: state.enqueteCredit,
      };

    case UPDATE_ENQUETE_CREDIT:
      return {
        ...state,
        enqueteCredit: state.enqueteCredit.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                total: action.payload.total,
                enqueteCredit: action.payload.enqueteCredit,
                creditScore: action.payload.creditScore,
                classe: action.payload.classe,

                venteAlarmeId: action.payload.venteAlarmeId,
              venteMobileId: action.payload.venteMobileId,
              venteInternetId: action.payload.venteInternetId,

                natureVente: action.payload.natureVente,
                operateurId: action.payload.operateurId,
                addressId: action.payload.addressId,
                contactId: action.payload.contactId,
                etapeId: action.payload.etapeId,
                historiqueFiche: action.payload.historiqueFiche,
              }
            : content
        ),
      };
    case GET_CONFIRMATION_RDV_INSTALLATION:
      return {
        ...state,
        confirmationRDVInstallation: action.payload,
      };
    case DELETE_CONFIRMATION_RDV_INSTALLATION:
      state.confirmationRDVInstallation.splice(
        state.confirmationRDVInstallation.findIndex(
          (e) => e._id === action.payload
        ),
        1
      );
      return {
        ...state,
        confirmationRDVInstallation: state.confirmationRDVInstallation,
      };
    case UPDATE_CONFIRMATION_RDV_INSTALLATION:
      return {
        ...state,
        confirmationRDVInstallation: state.confirmationRDVInstallation.map(
          (content) =>
            content._id === action.payload._id
              ? {
                  ...content,
                  // dureePrevInstallation: action.payload.dureePrevInstallation,
                  //               installateurId: action.payload.installateurId,
                  total: action.payload.total,
                  venteAlarmeId: action.payload.venteAlarmeId,
                  venteMobileId: action.payload.venteMobileId,
                  venteInternetId: action.payload.venteInternetId,
                  natureVente: action.payload.natureVente,
                  operateurId: action.payload.operateurId,
                  addressId: action.payload.addressId,
                  contactId: action.payload.contactId,
                  etapeId: action.payload.etapeId,
                  historiqueFiche: action.payload.historiqueFiche,
                }
              : content
        ),
      };
    case GET_VENTE_NIVEAU_INSTALLATEUR:
      return {
        ...state,
        venteNiveauInstallateur: action.payload,
      };
    case DELETE_VENTE_NIVEAU_INSTALLATEUR:
      state.venteNiveauInstallateur.splice(
        state.venteNiveauInstallateur.findIndex(
          (e) => e._id === action.payload
        ),
        1
      );
      return {
        ...state,
        venteNiveauInstallateur: state.venteNiveauInstallateur,
      };
    case UPDATE_VENTE_NIVEAU_INSTALLATEUR:
      return {
        ...state,
        venteNiveauInstallateur: state.venteNiveauInstallateur.map((content) =>
          content._id === action.payload._id
            ? {
                ...content,
                historiqueFiche: action.payload.historiqueFiche,
              }
            : content
        ),
      };
    case GET_COMMANDES:
      return {
        ...state,
        commandes: action.payload,
      };
    case ADD_COMMANDE:
      return {
        ...state,
        commandes: [action.payload, ...state.commandes],
      };
    case UPDATE_COMMANDE:
      return {
        ...state,
        commandes: state.commandes.map((content) =>
          content._id === action.payload.id
            ? {
                ...content,
                dateVente: action.payload.dateVente,
                dateInstallation: action.payload.dateInstallation,
                creatorNote: action.payload.creatorNote,
              }
            : content
        ),
      };
    case UPDATE_COMMANDE_STATUS:
      return {
        ...state,
        commandes: state.commandes.map((content) =>
          content._id === action.payload.id
            ? {
                ...content,
                approved: action.payload.approved,
                superviseurId: action.payload.superviseurId,
                managerNote: action.payload.managerNote,
              }
            : content
        ),
      };
    case DELETE_COMMANDE:
      state.commandes.splice(
        state.commandes.findIndex((e) => e._id === action.payload),
        1
      );
      return {
        ...state,
        commandes: state.commandes,
      };
    default:
      return state;
  }
}
