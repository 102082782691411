import {
  SET_CURRENT_USER,
  USER_LOADING,
  GET_USERS,
  UPDATE_USER,
  ADD_USER,
  GET_USER_DETAILS,
  GET_JOURNAL,
  GET_JOURNAL_STAT,
} from "../actions/types";
const isEmpty = require("is-empty");
const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  listUser: [],
  info: {},
  statut: "",
  journal: { journal: [] },
  statJournal: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        info: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_USER:
      return {
        ...state,
        listUser: [...state.listUser, action.payload],
      };
    case GET_USERS:
      return {
        ...state,
        listUser: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        listUser: state.listUser.map((content) =>
          content._id === action.payload.id
            ? {
                ...content,
                actif: action.payload.actif,
                username: action.payload.username,
                post: action.payload.post,
                mailEnregistrement: action.payload.mailEnregistrement,
                mdpMailEnregistrement: action.payload.mdpMailEnregistrement,
                mailBoiteVocal: action.payload.mailBoiteVocal,
                mdpMailBoiteVocal: action.payload.mdpMailBoiteVocal,
              }
            : content
        ),
      };
    case GET_JOURNAL:
      return {
        ...state,
        journal: action.payload,
      };
    case GET_JOURNAL_STAT:
      return {
        ...state,
        statJournal: action.payload,
      };
    default:
      return state;
  }
}
