import {
  GET_EQUIPEMENTS,
  ADD_EQUIPEMENT,
  UPDATE_EQUIPEMENT,
  DELETE_EQUIPEMENT,
  GET_FORFAITS,
  ADD_FORFAIT,
  UPDATE_FORFAIT,
  DELETE_FORFAIT
} from "../actions/types";

const initialState = {
  equipements: [],
  forfaits: [],
  commande: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EQUIPEMENTS:
      return {
        ...state,
        equipements: action.payload
      };
    case ADD_EQUIPEMENT:
      return {
        ...state,
        equipements: [action.payload, ...state.equipements]
      };
    case UPDATE_EQUIPEMENT:
      return {
        ...state,
        equipements: state.equipements.map(
          content =>
            content._id === action.payload._id
              ? {
                  ...content,
                  nom: action.payload.nom,
                  prix: action.payload.prix,
                  description: action.payload.description,
                  internet: action.payload.internet,
                  sonnete: action.payload.sonnete,
                  serrure: action.payload.serrure,
                  compagnieId: action.payload.compagnieId,
                  approche:action.payload.approche,

                  type:action.payload.type,
                  prixLocation:action.payload.prixLocation,
                  taxeAchatTotal:action.payload.taxeAchatTotal,
                  taxeLocationTotal:action.payload.taxeLocationTotal,
                  taxeAchatMensuel:action.payload.taxeAchatMensuel,
                  taxeLocationMensuel:action.payload.taxeLocationMensuel,
                  dateExpirationAchat:action.payload.dateExpirationAchat,
                  dateExpirationLocation:action.payload.dateExpirationLocation,

                }
              : content
        )
      };
    case DELETE_EQUIPEMENT:
      state.equipements.splice(
        state.equipements.findIndex(e => e._id === action.payload),
        1
      );
      return {
        ...state,
        equipements: state.equipements
      };
    case GET_FORFAITS:
      return {
        ...state,
        forfaits: action.payload
      };
    case ADD_FORFAIT:
      return {
        ...state,
        forfaits: [action.payload, ...state.forfaits]
      };

    case UPDATE_FORFAIT:
      return {
        ...state,
        forfaits: state.forfaits.map(
          content =>
            content._id === action.payload._id
              ? {
                  ...content,
                  nom: action.payload.nom,
                  equipements: action.payload.equipements,
                  duree: action.payload.duree,
                  prix: action.payload.prix,
                  moisGratuit: action.payload.moisGratuit,
                  rabaisAns: action.payload.rabaisAns,
                  rabaisTelusKoodo: action.payload.rabaisTelusKoodo,
                  rabaisDoubleService: action.payload.rabaisDoubleService,
                  rabaisTadt: action.payload.rabaisTadt,
                  // rabaisLigne: action.payload.rabaisLigne,
                  appliqRabais: action.payload.appliqRabais,
                  packageType: action.payload.packageType,
                  activation: action.payload.activation,
                  dateEnd: action.payload.dateEnd,
                  dateDebut: action.payload.dateDebut,
                  compagnieId: action.payload.compagnieId,
                  fraisInstallation: action.payload.fraisInstallation
                }
              : content
        )
      };
    case DELETE_FORFAIT:
      state.forfaits.splice(
        state.forfaits.findIndex(e => e._id === action.payload),
        1
      );
      return {
        ...state,
        forfaits: state.forfaits
      };
    default:
      return state;
  }
}
