import axios from "axios";
import Swal from "sweetalert2";
import {
  GET_CALL_HISTORY,
  ADD_APPEL_EMETTRE,
  DELETE_APPEL_EMETTRE,
  UPDATE_APPEL_EMETTRE,
  UPDATE_TIME_APPEL_EMETTRE,
  UPDATE_DATE_APPEL_EMETTRE,
  UPDATE_APPEL_EMETTRE_NOTIFIED,
  GET_APPEL_EMETTRE,
  GET_ETAPES,
  UPDATE_ETAPE,
  ADD_NOTE,
  GET_NOTES,
  DELETE_NOTE,
  GET_CALLS,
  GET_CALLS_CHIP,
  DELETE_APPEL_EMETTRE_BY_CONTACT,
  UPDATE_APPEL_EMETTRE_BY_CONTACT,
  GET_CONTACTS_NOT_AFFECTED,
  GET_CONTACTS,
  GET_VENTES,
  GET_CONTACTS_SOUS_CONTRAT_BY_ID,
  GET_VENTE_BY_ID,
  GET_AFFECTED_CONTACTS,
  GET_AFFECTED_CONTACTS_ALARME,
  GET_AFFECTED_CONTACTS_MOBILE,
  GET_CALL_ID,
  GET_RH_TIMELINE,
  ACCEPT_AFFECTED_CONTACT,
  UPLOAD_CONTACTS,
  GET_RDV,
  ADD_RDV,
  UPDATE_RDV_BY_USER,
  GET_SOUS_CONTRAT,
  GET_FUTURE_ECHEANCE,
  GET_FUTURE_BESOIN,
  GET_HORS_CIBLE,
  GET_SOUS_CONTRAT_ALARME,
  GET_FUTURE_ECHEANCE_ALARME,
  GET_FUTURE_BESOIN_ALARME,
  GET_KO_BUDGET_ALARME,
  GET_KO_AUTORITE_ALARME,
  GET_KO_REFUS_ALARME,
  GET_PAS_INTERESSE_ALARME,
  GET_KO_AUTRE_ALARME,
  GET_SOUS_CONTRAT_MOBILE,
  GET_FUTURE_ECHEANCE_MOBILE,
  GET_FUTURE_BESOIN_MOBILE,
  GET_KO_BUDGET_MOBILE,
  GET_KO_AUTORITE_MOBILE,
  GET_KO_REFUS_MOBILE,
  GET_PAS_INTERESSE_MOBILE,
  GET_KO_AUTRE_MOBILE,
  GET_RDV_AFFECTED_TO_USER,
  GET_CALLS_FOR_DISPLAY,
  UPDATE_RDV_BY_CONTACT,
  UPDATE_TIME_RDV,
  GET_RESEARCH_JOURNAL,
  GET_DEMANDE_AFFECTAION,
  UPDATE_DEMANDE_AFFECTAION,
  GET_CONTACTS_NOT_AFFECTED_ALARME,
  GET_CONTACTS_NOT_AFFECTED_MOBILE,
  GET_CONTACTS_HORSCIRCUIT_ALARME,
  UPDATE_CONTACTS_HORSCIRCUIT_ALARME,
  UPDATE_CONTACT,
  GET_SALES_FOR_DISPLAY
} from "./types";
import { successAlert, uploadErrorAlert, errorAlert,successAlertWithoutTime } from "./alertActions";

export const addRDV = (value) => (dispatch) => {
  axios
    .post("rdv/", {
      note: value.note,
      dateAppel: value.dateAppel,
      important: value.important,
      contactId: value.contactId,
      typeRDV: value.typeRDV,
      approche: value.approche,
      statutAlarme: value.statutAlarme,
      statutMobile: value.statutMobile,
      // dateFin: value.dateFin,
    })
    .then(function (response) {
      successAlert("le rendez-vous a été ajouté avec succès");
      dispatch({
        type: ADD_RDV,
        payload: response.data,
      });
    })
    .catch(function (error) {
      errorAlert("Problème lors de l'ajout du rendez-vous");
      console.log(error);
    });
};
export const getRDV = (value) => (dispatch) => {
  axios
    .post("rdv/getRDV", value)
    .then((res) => {
      dispatch({
        type: GET_RDV,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("Problème lors de la récuperation des rendez-vous");
      dispatch({
        type: GET_RDV,
        payload: [],
      });
    });
};
export const updateRDV = (value) => (dispatch) => {
  axios
    .post("rdv/updateRDV", value)
    .then((res) => {
      successAlert("le rendez-vous a été modifié avec succès");
      dispatch({
        type: UPDATE_RDV_BY_CONTACT,
        payload: res.data,
      });
      dispatch({
        type: UPDATE_RDV_BY_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("Problème lors de la mise à jour du rendez-vous");
      dispatch({
        type: UPDATE_RDV_BY_CONTACT,
        payload: [],
      });
      dispatch({
        type: UPDATE_RDV_BY_USER,
        payload: [],
      });
    });
};

export const updateTimeRDV = (value) => (dispatch) => {
  axios
    .post("rdv/updateTimeRDV", value)
    .then((res) => {
     // successAlert("le rendez-vous a été modifié avec succès");
      successAlertWithoutTime(`
    
        Date modifiée avec succès<br/>
        <a href="#" autofocus><b> Vous avez ${res.data.numRdvsAffectee} rendez-vous à cette date.</b></a>
        
      `);
      dispatch({
        type: UPDATE_TIME_RDV,
        payload: res.data.updRDV,
      });
     
    })
    .catch((err) => {
      errorAlert("Problème lors de la mise à jour du rendez-vous");
      dispatch({
        type:UPDATE_TIME_RDV,
        payload: [],
      });
    });
};

export const getAppelsEmettre = (value) => (dispatch) => {
  axios
    .post("appelEmettre/getAppelsEmettre", value)
    .then((res) => {
      dispatch({
        type: GET_APPEL_EMETTRE,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_APPEL_EMETTRE,
        payload: [],
      });
    });
};
export const getRDVAffectedToUser = (value) => (dispatch) => {
  axios
    .post("rdv/getRDVAffectedToUser", value)
    .then((res) => {
      dispatch({
        type: GET_RDV_AFFECTED_TO_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_RDV_AFFECTED_TO_USER,
        payload: [],
      });
    });
};

export const addAppelEmettre = (value) => (dispatch) => {
  axios
    .post("appelEmettre/", {
      note: value.note,
      dateAppel: value.dateAppel,
      important: value.important,
      contactId: value.contactId,
      raison: value.raison,
      typeAppel: value.typeAppel,
      typeRDV: value.typeRDV,
      approche: value.approche,
      assignedId: value.assignedId,
      assigned: value.assigned,
      statutAlarme: value.statutAlarme,
      statutMobile: value.statutMobile,
      // dateFin: value.dateFin,
    })
    .then(function (response) {
      successAlert("added Successfully");
      dispatch({
        type: ADD_APPEL_EMETTRE,
        payload: response.data,
      });
    })
    .catch(function (error) {
      errorAlert("OOPS");
      console.log(error);
    });
};

export const deleteAppelEmettre = (numero) => (dispatch) => {
  axios
    .delete("appelEmettre/", { data: { id: numero } })
    .then(function (response) {
      successAlert("deleted successfully");
      dispatch({
        type: DELETE_APPEL_EMETTRE,
        payload: numero,
      });
    })
    .catch(function (error) {
      errorAlert("OOPS");
      console.log(error);
    });
};
export const deleteAppelEmettreByContact = (numero) => (dispatch) => {
  axios
    .delete("appelEmettre/", { data: { id: numero } })
    .then(function (response) {
      successAlert("deleted successfully");
      dispatch({
        type: DELETE_APPEL_EMETTRE_BY_CONTACT,
        payload: numero,
      });
    })
    .catch(function (error) {
      errorAlert("OOPS");
      console.log(error);
    });
};

export const updateAppelEmettre = (value) => (dispatch) => {
  axios
    .put("appelEmettre/", value)
    .then((res) => {
      successAlert("updated successfully");
      dispatch({
        type: UPDATE_APPEL_EMETTRE,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
    });
};

export const updateTimeAppelEmettre =(value) =>(dispatch)=>{
  axios
  .put("appelEmettre/updateTimeAppelEmettre", value)
  .then((res) => {
    successAlertWithoutTime(`
    
    Date modifiée avec succès<br/>
    <a href="#" autofocus><b> Vous avez ${res.data.numAppelAEmettre} actions à émettre à cette date.</b></a>
    
  `);
    dispatch({
      type:UPDATE_TIME_APPEL_EMETTRE,
      payload: res.data.populatedupdated,
    });
  })
  .catch((err) => {
    errorAlert("OOPS");
  });
}

export const updateDateAppelEmettre = (value) => (dispatch) => {
  axios
    .put("appelEmettre/", value)
    .then((res) => {
      successAlert("updated successfully");
      dispatch({
        type: UPDATE_DATE_APPEL_EMETTRE,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
    });
};
export const updateAppelEmettreNotified = (value) => (dispatch) => {
  axios
    .put("appelEmettre/notified", value)
    .then((res) => {
      successAlert("Notifié");
      dispatch({
        type: UPDATE_APPEL_EMETTRE_NOTIFIED,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
    });
};
export const updateAppelEmettreByContact = (value) => (dispatch) => {
  axios
    .put("appelEmettre/", value)
    .then((res) => {
      successAlert("updated successfully");
      dispatch({
        type: UPDATE_APPEL_EMETTRE_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
    });
};

export const getEtapes = () => (dispatch) => {
  axios
    .get("etape/")
    .then((res) => {
      dispatch({
        type: GET_ETAPES,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_ETAPES,
        payload: [],
      });
    });
};

export const updateEtapes = (value) => (dispatch) => {
  axios
    .put("etape/", value)
    .then((res) => {
      successAlert("step updated successfully");
      dispatch({
        type: UPDATE_ETAPE,
        payload: res.data.etape,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
    });
};

export const getCalls = (value) => (dispatch) => {
  axios
    .post("call/getHistory", value)
    .then((res) => {
      dispatch({
        type: GET_CALLS,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_CALLS,
        payload: { call: [] },
      });
    });
};

export const getCallHistoryByOP = (value) => (dispatch) => {
  axios
    .post("call/getCallHistoryByOP", value)
    .then((res) => {
      dispatch({
        type: GET_CALLS_CHIP,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_CALLS_CHIP,
        payload: {},
      });
    });
};
export const getCallHistoryByAdmin = (value) => (dispatch) => {
  axios
    .post("call/getCallHistoryByAdmin", value)
    .then((res) => {
      dispatch({
        type: GET_CALLS_CHIP,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_CALLS_CHIP,
        payload: {},
      });
    });
};

export const getContacts = (value) => (dispatch) => {
  axios
    .post("contact/byOptions", value)
    .then((res) => {
      dispatch({
        type: GET_CONTACTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_CONTACTS,
        payload: { contacts: [] },
      });
    });
};

export const getContactsWithPhoneNumber = (value) => (dispatch) => {
  axios
    .post("contact/byOptionsWithPhoneNumber", value)
    .then((res) => {
      dispatch({
        type: GET_CONTACTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_CONTACTS,
        payload: { contacts: [] },
      });
    });
};
export const getContactsNotAffectedByOpByApproche = (value) => (dispatch) => {
  axios
    .post("contact/getContactsNotAffectedByOpByApproche", value)
    .then((res) => {
      dispatch({
        type: GET_CONTACTS_NOT_AFFECTED,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_CONTACTS_NOT_AFFECTED,
        payload: [],
      });
    });
};
export const getContactsNotAffectedByOpApprocheAlarme =
  (value) => (dispatch) => {
    axios
      .post("contact/getContactsNotAffectedByOpApprocheAlarme", value)
      .then((res) => {
        dispatch({
          type: GET_CONTACTS_NOT_AFFECTED_ALARME,
          payload: res.data,
        });
      })
      .catch((err) => {
        errorAlert("OOPS");
        dispatch({
          type: GET_CONTACTS_NOT_AFFECTED_ALARME,
          payload: [],
        });
      });
  };
export const getContactsNotAffectedByOpApprocheMobile =
  (value) => (dispatch) => {
    axios
      .post("contact/getContactsNotAffectedByOpApprocheMobile", value)
      .then((res) => {
        dispatch({
          type: GET_CONTACTS_NOT_AFFECTED_MOBILE,
          payload: res.data,
        });
      })
      .catch((err) => {
        errorAlert("OOPS");
        dispatch({
          type: GET_CONTACTS_NOT_AFFECTED_MOBILE,
          payload: [],
        });
      });
  };
export const getVentes = (value) => (dispatch) => {
  axios
    .post("api/prospectionCommerciale/byOptions", value)
    .then((res) => {
      dispatch({
        type: GET_VENTES,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_VENTES,
        payload: { ventes: [] },
      });
    });
};
// axios
//     .get("api/prospectionCommerciale/xxxx", value)
//     .then((res) => {

//     })
//     .catch((err) => {
//       errorAlert("OOPS");

//     });
// };
export const getVenteById = (id) => (dispatch) => {
  axios
    .get("api/prospectionCommerciale/byIdVente/" + id)
    .then((res) => {
      dispatch({
        type: GET_VENTE_BY_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_VENTE_BY_ID,
        payload: { venteByID: {} },
      });
    });
};
// export const getVentes = (value) => (dispatch) => {
//   console.log("Action", value)
//   axios
//     .post("api/prospectionCommerciale/byOptions", value)
//     .then((res) => {
//       dispatch({
//         type: GET_VENTES,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       errorAlert("OOPS");
//       dispatch({
//         type: GET_VENTES,
//         payload: [],
//       });
//     });
// };

export const getContactSousContratById = (value) => (dispatch) => {
  axios
    .post("adresse/contrat/byId", value)
    .then((res) => {
      dispatch({
        type: GET_CONTACTS_SOUS_CONTRAT_BY_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACTS_SOUS_CONTRAT_BY_ID,
        payload: [],
      });
    });
};
export const getNotesDynamiques = () => (dispatch) => {
  axios
    .get("noteDynamique/")
    .then((res) => {
      dispatch({
        type: GET_NOTES,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_NOTES,
        payload: [],
      });
    });
};

export const updateNoteDynamique = (value) => (dispatch) => {
  axios
    .put("noteDynamique/", value)
    .then((res) => {
      // successAlert("Note modifiée avec succès");
      // dispatch({
      //   type: UPDATE_NOTE,
      //   payload: res.data.etape,
      // });
    })
    .catch((err) => {
      errorAlert("OOPS");
      console.log(err);
    });
};

export const deleteNoteDynamique = (numero) => (dispatch) => {
  axios
    .delete("noteDynamique/", { data: { idNote: numero } })
    .then(function (response) {
      successAlert("Note supprimée avec succès");
      dispatch({
        type: DELETE_NOTE,
        payload: numero,
      });
    })
    .catch(function (error) {
      errorAlert("OOPS");
      // console.log(error);
    });
};

export const addNoteDynamique = (value) => (dispatch) => {
  axios
    .post("noteDynamique/", {
      nom: value.nom,
      libelle: value.libelle,
      content: value.content,
    })
    .then(function (response) {
      successAlert("Note ajoutée avec succès");
      dispatch({
        type: ADD_NOTE,
        payload: response.data,
      });
    })
    .catch(function (error) {
      errorAlert("OOPS");
      console.log(error);
    });
};

export const getAffectedContactByOpByApproche = (value) => (dispatch) => {
  axios
    .post("contact/getAffectedContactByOpByApproche", value)
    .then((res) => {
      console.log("zss", res.data)
      dispatch({
        type: GET_AFFECTED_CONTACTS,
        payload: res.data,
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors du traitement de la requête");
      dispatch({
        type: GET_AFFECTED_CONTACTS,
        payload: [],
      });
    });
};

export const updateCall = (value) => (dispatch) => {
  axios
    .post("call/updateCall/", value)
    .then((res) => {
      successAlert(
        "Statut Appel: " +
          res.data.statutAppel +
          "\n" +
          "  Approche: " +
          res.data.approche
      );
      dispatch({
        type: GET_CALL_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_CALL_ID,
        payload: {},
      });
    });
};
export const getRhTimeLine = (value) => (dispatch) => {
  axios
    .post("api/rh/timeLine", value)
    .then((res) => {
      successAlert("getRhTimeLine");
      dispatch({
        type: GET_RH_TIMELINE,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_RH_TIMELINE,
        payload: {},
      });
    });
};

export const uploadContacts = (value) => (dispatch) => {
  axios
    .post("contact/uploadContacts", value)
    .then((res) => {
      console.log("res.status", res.status);
      if (res.status === 201) {
        Swal.fire({
          title: "Erreur d'intégration !",
          text:
          res.data.length +
          " contact(s) n'a (ont) pas pu être integré(s), merci de réessayer\n" +"("
           +
           res.data.map(el => `${el.numero}\n`)+")"
          ,
          icon: "warning",
          footer:
            "procéder à une correction, et re-sélectioner les contacts à intégrer",
          confirmButtonText: "ok",
        });
      }
      if (res.status === 200) {
        Swal.fire({
          title: "Toute la sélection a été integré avec succes !",

          icon: "success",

          confirmButtonText: "ok",
        });
      }
      if(res.status===404){
        Swal.fire({
          title: "intégration échouée 😞 ",
          icon: "error",
          confirmButtonText: "ok",
        });
      }

      dispatch({
        type: UPLOAD_CONTACTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      // errorAlert("OOPS");
      dispatch({
        type: UPLOAD_CONTACTS,
        payload: [],
      });
    });
};

export const acceptAffectedContact = (value) => (dispatch) => {
  axios
    .post("contact/acceptAffectedContact", value)
    .then((res) => {
      console.log("yyyk", res.data);
      successAlert("Affectation acceptée");
      dispatch({
        type: ACCEPT_AFFECTED_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: ACCEPT_AFFECTED_CONTACT,
        payload: {},
      });
    });
};

export const getCallsForDisplay = (value) => (dispatch) => {
  axios
    .post("call/getCallsForDisplay", value)
    .then((res) => {
      dispatch({
        type: GET_CALLS_FOR_DISPLAY,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_CALLS_FOR_DISPLAY,
        payload: { callsForDisplay: [] },
      });
    });
};

export const addResearchJournal = (value) => (dispatch) => {
  axios
    .post("researchJournal/addResearchJournal", value)
    .then(function (response) {})
    .catch(function (error) {
      errorAlert("Problème lors de la mise à jour du journal de recherche");
      console.log(error);
    });
};
export const getResearchJournal = (value) => (dispatch) => {
  axios
    .post("researchJournal/getResearchJournal", value)
    .then(function (response) {
      dispatch({
        type: GET_RESEARCH_JOURNAL,
        payload: response.data,
      });
    })
    .catch(function (error) {
      errorAlert("Problème lors de la récuperation du journal de recherche");
      console.log(error);
    });
};

export const getDemandeAffectation = (value) => (dispatch) => {
  axios
    .post("demandeAffectation/getDemandeAffectation", value)
    .then((res) => {
      dispatch({
        type: GET_DEMANDE_AFFECTAION,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_DEMANDE_AFFECTAION,
        payload: [],
      });
    });
};
export const updateDemandeAffectation = (value) => (dispatch) => {
  axios
    .post("demandeAffectation/updateDemandeAffectation", value)
    .then((res) => {
      dispatch({
        type: UPDATE_DEMANDE_AFFECTAION,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: UPDATE_DEMANDE_AFFECTAION,
        payload: [],
      });
    });
};
export const addActionEmettreFromAffectation = (value) => (dispatch) => {
  console.log("ddd", value);
  axios
    .post("appelEmettre/addActionEmettreFromAffectation", value)
    .then(function (response) {
      successAlert("Action(s) à eméttre crée(s) avec succée");
    })
    .catch(function (error) {
      errorAlert("Problème lors de la création des actions à émettre");
      console.log(error);
    });
};

export const getSContrat = (value) => (dispatch) => {
  axios
    .post("adresse/contrat/byUser", value)
    .then((res) => {
      dispatch({
        type: GET_SOUS_CONTRAT,
        payload: res.data.filter((e) => e.contrat.type === "Alame"),
        payload:
          value.approche === "Alarme"
            ? res.data.filter((e) => e.contrat.type === "Alarme")
            : value.approche === "Mobile"
            ? res.data.filter((e) => e.contrat.type === "Mobile")
            : [],
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors du traitement de la requête");
      dispatch({
        type: GET_SOUS_CONTRAT,
        payload: [],
      });
    });
};
export const getEcheance = (value) => (dispatch) => {
  axios
    .post("contact/futureLead/byUser", value)
    .then((res) => {
      dispatch({
        type: GET_FUTURE_ECHEANCE,
        payload:
          value.approche === "Alarme"
            ? res.data.futureLeadEcheance
            : value.approche === "Mobile"
            ? res.data.futureLeadEcheanceMobile
            : [],
      });
      console.log("getEcheance", res.data);
    })
    .catch(function (error) {
      errorAlert("Erreur lors du traitement de la requête");
      dispatch({
        type: GET_FUTURE_ECHEANCE,
        payload: [],
      });
    });
};
export const getBesoin = (value) => (dispatch) => {
  axios
    .post("contact/futureLead/byUser", value)
    .then((res) => {
      dispatch({
        type: GET_FUTURE_BESOIN,
        payload:
          value.approche === "Alarme"
            ? res.data.futureLeadBesoin
            : value.approche === "Mobile"
            ? res.data.futureLeadBesoinMobile
            : [],
      });
      console.log("getBesoin", res.data);
    })
    .catch(function (error) {
      errorAlert("Erreur lors du traitement de la requête");
      dispatch({
        type: GET_FUTURE_BESOIN,
        payload: [],
      });
    });
};
export const hCible = (value) => (dispatch) => {
  axios
    .post("contact/futureLead/byUser", value)
    .then((res) => {
      dispatch({
        type: GET_HORS_CIBLE,
        payload:
          value.approche === "Alarme"
            ? res.data.hCibleAlarme
            : value.approche === "Mobile"
            ? res.data.hCibleMobile
            : [],
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors du traitement de la requête");
      dispatch({
        type: GET_HORS_CIBLE,
        payload: [],
      });
    });
};
export const getContactsByStatutByUserByApproche = (value) => (dispatch) => {
  axios
    .post("contact/getContactsByStatutByUserByApproche", value)
    
    .then((res) => {
      if (value.type === "echeance")
        dispatch({
          type: GET_FUTURE_ECHEANCE,
          payload: res.data.futureLeadEcheance,
        });
      else if (value.type === "besoin")
        dispatch({
          type: GET_FUTURE_BESOIN,
          payload: res.data.futureLeadBesoin,
        });
      else if (value.type === "cible")
        dispatch({
          type: GET_HORS_CIBLE,
          payload: res.data.hCible,
        });
      else if (value.type === "sousContrat")
        dispatch({
          type: GET_SOUS_CONTRAT,
          payload: res.data.sContrat,
        });
    })
    .catch(function (error) {
      errorAlert("Erreur lors du traitement de la requête");
      dispatch({
        type: GET_HORS_CIBLE,
        type: GET_FUTURE_ECHEANCE,
        type: GET_FUTURE_BESOIN,
        payload: [],
      });
    });
};
export const getContactsByStatutByUserApprocheAlarme =
  (value) => (dispatch) => {
    axios
      .post("contact/getContactsByStatutByUserApprocheAlarme", value)
      .then((res) => {
        dispatch({
          type: GET_FUTURE_ECHEANCE_ALARME,
          payload: res.data.futureLeadEcheance,
        });

        dispatch({
          type: GET_FUTURE_BESOIN_ALARME,
          payload: res.data.futureLeadBesoin,
        });

        dispatch({
          type: GET_KO_BUDGET_ALARME,
          payload: res.data.koBudget,
        });

        dispatch({
          type: GET_SOUS_CONTRAT_ALARME,
          payload: res.data.sContrat,
        });
        dispatch({
          type: GET_KO_AUTORITE_ALARME,
          payload: res.data.koAutoriteAlarme,
        });
        dispatch({
          type: GET_KO_REFUS_ALARME,
          payload: res.data.koRefusAlarme,
        });
        dispatch({
          type: GET_PAS_INTERESSE_ALARME,
          payload: res.data.pasInteresseAlarme,
        });
        dispatch({
          type: GET_KO_AUTRE_ALARME,
          payload: res.data.koAutreAlarme,
        });
      })
      .catch(function (error) {
        errorAlert("Erreur lors du traitement de la requête");
        dispatch({
          type: GET_KO_BUDGET_ALARME,
          type: GET_FUTURE_ECHEANCE_ALARME,
          type: GET_FUTURE_BESOIN_ALARME,
          type: GET_SOUS_CONTRAT_ALARME,
          type: GET_KO_AUTORITE_ALARME,
          type: GET_KO_REFUS_ALARME,
          type: GET_PAS_INTERESSE_ALARME,
          type: GET_KO_AUTRE_ALARME,
          payload: [],
        });
      });
  };
export const getContactsByStatutByUserApprocheMobile =
  (value) => (dispatch) => {
    axios
      .post("contact/getContactsByStatutByUserApprocheMobile", value)
      .then((res) => {
        dispatch({
          type: GET_FUTURE_ECHEANCE_MOBILE,
          payload: res.data.futureLeadEcheance,
        });

        dispatch({
          type: GET_FUTURE_BESOIN_MOBILE,
          payload: res.data.futureLeadBesoin,
        });

        dispatch({
          type: GET_KO_BUDGET_MOBILE,
          payload: res.data.koBudget,
        });

        dispatch({
          type: GET_SOUS_CONTRAT_MOBILE,
          payload: res.data.sContrat,
        });

        dispatch({
          type: GET_KO_AUTORITE_MOBILE,
          payload: res.data.koAutoriteMobile,
        });

        dispatch({
          type: GET_KO_REFUS_MOBILE,
          payload: res.data.koRefusMobile,
        });

        dispatch({
          type: GET_PAS_INTERESSE_MOBILE,
          payload: res.data.pasInteresseMobile,
        });

        dispatch({
          type: GET_KO_AUTRE_MOBILE,
          payload: res.data.koAutreMobile,
        });
      })
      .catch(function (error) {
        errorAlert("Erreur lors du traitement de la requête");
        dispatch({
          type: GET_KO_BUDGET_MOBILE,
          type: GET_FUTURE_ECHEANCE_MOBILE,
          type: GET_FUTURE_BESOIN_MOBILE,
          type: GET_SOUS_CONTRAT_MOBILE,
          type: GET_KO_AUTORITE_MOBILE,
          type: GET_KO_REFUS_MOBILE,
          type: GET_PAS_INTERESSE_MOBILE,
          type: GET_KO_AUTRE_MOBILE,
          payload: [],
        });
      });
  };
export const getAffectedContactByOpApprocheAlarme = (value) => (dispatch) => {
  axios
    .post("contact/getAffectedContactByOpApprocheAlarme", value)
    .then((res) => {
      dispatch({
        type: GET_AFFECTED_CONTACTS_ALARME,
        payload: res.data,
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors du traitement de la requête");
      dispatch({
        type: GET_AFFECTED_CONTACTS_ALARME,
        payload: [],
      });
    });
};
export const getAffectedContactByOpApprocheMobile = (value) => (dispatch) => {
  axios
    .post("contact/getAffectedContactByOpApprocheMobile", value)
    .then((res) => {
      dispatch({
        type: GET_AFFECTED_CONTACTS_MOBILE,
        payload: res.data,
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors du traitement de la requête");
      dispatch({
        type: GET_AFFECTED_CONTACTS_MOBILE,
        payload: [],
      });
    });
};

export const addCallManuel =(value)=>(dispatch)=>{
  axios
  .post("/call/addCallManuel",value)
  .then((res)=>{
    successAlert("Appel ajouté avec succés")
  }) 
  .catch((err) => {
    errorAlert("Echec d'ajout d'appel");
    // dispatch({ baya dispatch update call
    //   type: GET_CALL_ID,
    //   payload: {},
    // });
  });
  axios
    .get("call/history/" + value.numeroParams)
    .then((res) => {
      dispatch({
        type: GET_CALL_HISTORY,
        payload:res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CALL_HISTORY,
        payload: [],
      });
    });
}

export const getContactsHorsCircuit = ()=>(dispatch)=>{
axios
.get("/contact/getContactsHorsCircuit")
.then((res)=>{
  dispatch({
    type:GET_CONTACTS_HORSCIRCUIT_ALARME,
    payload:res.data
  })
})
.catch((error)=>{
  console.log({error:error});
  errorAlert("Echec de la récupération des contacts hors circuit.");
  dispatch({
    type:GET_CONTACTS_HORSCIRCUIT_ALARME,
    payload:[]
  })
})
}
export const updateContactsHors = (value)=>(dispatch)=>{
  axios
  .put("/contact/updateContactsHors",value)
  .then((res)=>{
    successAlert("Contact retiré avec succès");
    dispatch({
      type:UPDATE_CONTACTS_HORSCIRCUIT_ALARME,
      payload:res.data
    })
    dispatch({
      type:UPDATE_CONTACT,
      payload:res.data,
    })
  })
  .catch((error)=>{
    console.log({error:error});
    errorAlert("Échec lors du retrait du contact");
    dispatch({
      type:UPDATE_CONTACTS_HORSCIRCUIT_ALARME,
      payload:{}
    })
  })
}

export const getProspectionCommercialeTV4 = (value) =>(dispatch) =>{
  axios
  .get("/api/prospectionCommerciale/getProspectionCommercialeTV4")
  .then((res)=>{
    dispatch({
      type:GET_SALES_FOR_DISPLAY,
      payload:res.data

    })
  })
  .catch((error)=>{
  console.log({error:error})
  dispatch({
    type:GET_SALES_FOR_DISPLAY,
    payload:[]
  })
  })
}